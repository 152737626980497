import { createRouter, createWebHistory } from 'vue-router'
import PythonHome from '../views/PythonHome.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: PythonHome
  },
  {//==============================(Introduction)=================================
    path: '/introduction',
    name: 'introduction',
    component: () => import('../views/PythonIntroduction.vue')
  },
    {//==============================(Code Editor)=================================
      path: '/codeEditor',
      name: 'code editor',
      component: () => import('../views/CodeEditor.vue')
    },
    {//=================================(User)====================================
      path: '/login',
      name: 'login',
      component: () => import('../views/LoginView.vue')
    },
    {//Admin only
      path: '/users',
      name: 'users',
      component: () => import('../views/UsersView.vue')
    },
    {
      path: '/datas',
      name: 'datas',
      component: () => import('../views/DataView.vue')
    },
    {
      path: '/userAdd',
      name: 'userAdd',
      component: () => import('../views/UserAdd.vue')
    },
    {
      path: '/userEdit/:id',
      name: 'userEdit',
      component: () => import('../views/UserEdit.vue')
    },
    {//=================================(Course)====================================
      path: '/courseAdd',
      name: 'courseAdd',
      component: () => import('../views/CourseAddView.vue')
    },
    {
      path: '/course',
      name: 'course',
      component: () => import('../views/CoursePaginationView.vue')
    },
    {
      path: '/course/:id',
      name: 'course_detail',
      component: () => import('../views/CourseDetailView.vue')
    },
    {
      path: '/course/edit/:id',
      name: 'course_edit',
      component: () => import('../views/EditCourseView.vue')
    },
    {//=================================(Topic)====================================
      path: '/topicApplication/:id',
      name: 'topicApplication',
      component: () => import('../views/TopicApplicationView.vue')
    },
    {//=================================(Tutorial)====================================
      path: '/tutorialsmanagement',
      name: 'tutorialsmanagement',
      component: () => import('../views/TutorialsManagementView.vue')
    },
    {
      path: '/tutorialAdd',
      name: 'tutorialadd',
      component: () => import('../views/AddTutorialView.vue')
    },
    {
      path: '/tutorialEdit/:id',
      name: 'tutorialedit',
      component: () => import('../views/TutorialEditView.vue')
    },
    {
      path: '/tutorial/:id',
      name: 'tutorialview',
      component: () => import('../views/TutorialView.vue')
    },
    {//=================================(Test Component)====================================
      path: '/TestComponent',
      name: 'TestComponent',
      component: () => import('../views/TestComponentView.vue')
    },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
