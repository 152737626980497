<template>
  <nav class="navbar navbar-expand-sm navbar-light bg-light">
    <div class="container-fluid">
      <div class="collapse navbar-collapse gap-1" id="collapsibleNavId">
        <ul class="navbar-nav me-auto mt-2 mt-lg-0">
          <li class="nav-item ">
            <a class="nav-link fs-5 text-primary fw-bold btn-h" href="/">Python Online Practice System</a>
          </li>
          <li class="nav-item">
            <a class="nav-link fs-5 text-black btn-h" href="/">Introduction</a>
          </li>
          <li class="nav-item">
            <a class="nav-link fs-5 btn-h" href="/codeEditor">Code Editor</a>
          </li>
        </ul>
        <div class="card" v-if="token">
          <div class="fs-3">{{ user_type[0].toUpperCase() }}</div>
        </div>
        <div class="card" v-if="token">
          <div class="fs-9">{{ userID }}</div>
          <div class="fs-9">{{ name }}</div>
        </div>
        <button type="button" class="btn btn-primary" @click="debug"  v-if="false">Debug</button>
        <a class="btn btn-primary" type="submit" href="/tutorialsmanagement"  v-if="a">Tutorials</a>
        <a class="btn btn-primary" type="submit" href="/course"  v-if="token">My Course</a>
        <a class="btn btn-primary" type="submit" href="/users"  v-if="a">Users</a>
        <button type="button" class="btn btn-primary" @click="logout"  v-if="token">Log Out</button>
        <a class="btn btn-primary" type="submit" href="/login" v-else>Sign in</a>
        <a class="btn btn-primary" type="submit" href="/userAdd" v-if="!token">Sign up</a>
      </div>
    </div>
  </nav>
  <div class="text-bg-primary" style="height: 2px;"></div>
  <router-view />
</template>
<script setup>
import { jwtDecode } from 'jwt-decode'
import { provide } from "vue";
//import { useRoute } from "vue-router";
let a = "";
let token = localStorage.getItem("user")
let userID = ''
let user_type = ''
let name = ''
if(token){
  userID = jwtDecode(token).account
  a = jwtDecode(token).user_type == "admin";
  user_type = jwtDecode(token).user_type
  name = jwtDecode(token).name
}
provide('token', token)
const logout = function () {
  localStorage.removeItem('user');
  location.reload()
  location.assign('/')
}
const debug = async function(){
  let token = localStorage.getItem("user")
  let s = "/api/debuginsert/"
  var response = await fetch(s , 
  {method : "POST",                
  headers: {'Content-Type': 'application/json' },
  body: JSON.stringify({body: jwtDecode(token)._id})});
  if(response.ok){
    const res = await response.json();

    console.log(res.results)
  }
}

const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout (timer);
    timer = setTimeout (function () {
      fn.apply(context, args);
      }, delay);
    }
  }
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor (callback) {
    callback = debounce(callback, 16);
    super (callback) ;
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;

}

nav a.router-link-exact-active {
  color: #42b983;
}

.btn-h {
  border-radius: 40px;
}

.btn-h:hover{
  background-color:rgb(198, 233, 240);
  transition-duration: 0.4s;
}
</style>
