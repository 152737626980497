<template>
    <div class="text-bg-primary min-vh-100 h-100 d-flex align-content-start flex-wrap">
        <div class="btn-group-vertical col-12 d-flex " role="group" aria-label="Vertical button group">
            <div class="d-flex p-2 fs-5">Tutorial List</div>
            <button v-for="item in items" :key="item._id" type="button" 
            class="btn btn-secondary d-flex fs-5 p-2 m-1 border border-2 border-white rounded" 
            :class="item.done?'btn btn-success':'btn btn-secondary'" style="max-height: 3rem;" @click.prevent="redirect(item._id)">
            {{ item.tutoriald_name }}</button>
        <div class=""></div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { jwtDecode } from 'jwt-decode'
let token = localStorage.getItem("user")
const items = ref([])
onMounted(async ()=>{
    let s = '/api/tutorial/pagination/'
    s += token? jwtDecode(token)._id: ''
    var response = await fetch(s, {
      method: "get",
      headers: {
        "content-type": "application/json"
      },
    });
    if (response.ok) {
        var r = await response.json();
        items.value = r.results

    } else {
        alert(response.statusText)
    }
})
const redirect = (id)=>{location.assign('/tutorial/'+id)}
</script>